.status_bar {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    color: #fff;
    background-color: #333;
    padding: 5px 20px;
    z-index: 9999;
}
.signal_1 {
    color: rgb(13, 184, 13);
}

.connection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.status_desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .status_desc {
        font-size: 12px;
    }
    .status_bar {
        padding: 5px;
    }
}

@media screen and (max-width: 479px) {
    .status_desc {
        font-size: 11px;
    }
}
