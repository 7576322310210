.Toastify__close-button {
    flex: 1;
}
 
.max-fit-content {
    max-width: fit-content;
}
.theme-color {
    color: var(--color-primary);
}

.view_3d_container {
    height: calc(100vh - 100px - 30px);
    overflow: hidden;
    position: relative;
    width: 100%;
}

.view_3d_sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.view_3d_toolbar {
    padding: 10px;
    width: fit-content;
    position: relative;
}

.view_3d_info {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
}
