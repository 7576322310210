:root {
    // themes color
    --color-primary: #00a3ff;
    --color-primary-alta: #212e48;
    --color-primary-icon: #0e131e; // color of icons

    --color-gray: #f6f6f6;
    --color-gray-2: #f5f8fa;
    --color-subtitle: #f9004d;

    // background-color
    --background-color-1: #24243557;
    --background-color-2: #13131d;
    --background-color-3: #151521;
    --background-color-4: #242435;
    --background-color-5: #20222b; // color of cards on homepage

    // color gradient
    --gradient-one: linear-gradient(to right bottom, #2899d8, #00a3ff);

    // typo Color
    --color-heading: #ffffff;
    --color-body: #acacac;
    --color-dark: rgb(29, 29, 29);

    --color-light-heading: #181c32;
    --color-light-body: #65676b;
    --color-border-white: #00000024;

    // Gery Colors
    --color-midgray: #878787;
    --color-light: #e4e6ea;
    --color-lighter: #ced0d4;
    --color-lightest: #f0f2f5;
    --color-border: #ffffff14;
    --color-white: #ffffff;
    --color-white-75: rgba(255, 255, 255, 0.75);

    // notify Colors
    --color-success: #3eb75e;
    --color-danger: #ff0003;
    --color-warning: #ff8f3c;
    --color-info: #1ba2db;

    //Social icon colors
    --color-facebook: #3b5997;
    --color-twitter: #1ba1f2;
    --color-youtube: #ed4141;
    --color-linkedin: #0077b5;
    --color-pinterest: #e60022;
    --color-instagram: #c231a1;
    --color-vimeo: #00adef;
    --color-twitch: #6441a3;
    --color-discord: #7289da;

    // Font weight
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;

    // Font weight
    --s-light: 300;
    --s-regular: 400;
    --s-medium: 500;
    --s-semi-bold: 600;
    --s-bold: 700;
    --s-extra-bold: 800;
    --s-black: 900;

    //transition easing
    --transition: 0.4s;

    // Font Family
    --font-primary: "Roboto", sans-serif;
    --font-secondary: "Roboto", sans-serif;

    //Fonts Size
    --font-size-b1: 14px;
    --font-size-b2: 18px;
    --font-size-b3: 22px;

    //Line Height
    --line-height-b1: 1.5;
    --line-height-b2: 1.6;
    --line-height-b3: 1.7;

    // Heading Font
    --h1: 50px;
    --h2: 36px;
    --h3: 32px;
    --h4: 26px;
    --h5: 22px;
    --h6: 20px;
}

// Layouts Variation
$smlg-device: "only screen and (max-width: 1199px)";
$xxl-device: "only screen and (min-width: 1920px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$laptop-device2: "only screen and (min-width: 1200px) and (max-width: 1300px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";
