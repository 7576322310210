div[id^="datePicker-popupSurface"] {
    background-color: #222;
}
.hidden {
    display: none !important;
}
::-webkit-scrollbar {
    width: 3px;
}
::-webkit-scrollbar-track {
    background: #5e5e5e;
}
::-webkit-scrollbar-thumb {
    background: #e2e2e2;
}
::-webkit-scrollbar-thumb:hover {
    background: #8d8282;
}
/* 1000000 is z-index of fluentui dialog */
.swal2-container {
    z-index: 1000001 !important;
}

/* Override SunEditor's default theme to dark theme */
/* Toolbar icon color */
.sun-editor .sun-editor-button .icon {
    fill: #fff;
}
/* Toolbar */
.sun-editor .se-toolbar * {
    color: #ffffff;
}
.sun-editor .se-toolbar {
    background-color: #292929 !important;
}
.sun-editor .se-btn.se-tooltip:hover {
    background-color: #999999 !important;
}
.sun-editor .se-btn.se-tooltip:disabled * {
    color: #ffffff55;
}
.sun-editor .se-btn.se-tooltip.on {
    background-color: #999999 !important;
}
.sun-editor .se-btn.se-tooltip.active * {
    color: aqua !important;
}
/* Toolbar submenu */
.sun-editor .se-submenu {
    background-color: #292929;
}

.sun-editor .se-list-layer {
    background-color: #292929 !important;
}
.sun-editor .se-btn-list {
    background-color: #292929;
    color: white;
}
.sun-editor .se-btn-list.default_value {
    background-color: #999999 !important;
}
.sun-editor .se-btn-list:hover {
    background-color: #666666 !important;
}

/* edit area */
.sun-editor .se-placeholder {
    color: #ccc !important;
}
.sun-editor .sun-editor-editable {
    background-color: #292929 !important;
}
.sun-editor .sun-editor-editable * {
    color: #ffffff;
}
.sun-editor-editable p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ffffff;
}
.sun-editor .sun-editor-editable a {
    color: #1588ec;
}
.sun-editor .sun-editor-editable a:hover {
    color: #5ca9ec;
}

/*  */
.sun-editor .se-navigation.sun-editor-common,
.se-resizing-bar.sun-editor-common {
    background-color: #292929 !important;
    color: #ffffff !important;
}

/* image caption */
.sun-editor figcaption {
    background-color: #292929 !important;
}

/* code view */
.sun-editor .se-wrapper .se-wrapper-code {
    color: #ffffff !important;
    background-color: #666666 !important;
}
